 .box {
    border: 1px solid #dfdfdf;
    background-color: #fff;
  }
  
  .g-btn {
    width: 100% !important;
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px !important;
  }
  .g-btn > div,
  .g-btn > div > svg {
    width: 40px !important;
    height: 38px !important;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    width: auto;
  }
  .map-container {
    width: 100%;
    max-width: 800px;
    height: 400px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  .control-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    background-color: #d2d0d0;
  }
  
  .control-panel-button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #DE6D14;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
  }
  
  .control-panel-button:hover {
    background-color: #e78c47;
    color: white;
  }
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }
  
  .popup-close-btn {
    margin-top: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #DE6D14;
    cursor: pointer;
    transition-duration: 0.4s;
  }
  
  .popup-close-btn:hover {
    background-color: #DE6D14;
    color: white;
  }

  .purchase-button {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 20px auto;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #DE6D14;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.4s;
  }
  
  .purchase-button:hover {
    background-color: #e78c47;
    color: white;
  }

  .modal-header {
    justify-content: center;
  }
  
  .modal-title {
    font-weight: bold;
  }
  
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-body img {
    width: 50%;
    cursor: pointer;
  }

  .navbar-container {
    background-color: white;
  }

  .heading {
    display: flex;
    align-items: center;
    margin: 10px;
  }
  
  .info-icon {
    margin-left: 10px;
  }
  
  .button-link {
    display: flex;
    justify-content: flex-end;
  }
  
  .progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .progress-step {
    position: relative;
    padding: 10px 20px;
    color: #aaa;
    background-color: #ddd;
  }
  
  .progress-step:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #aaa;
    z-index: -1;
  }
  
  .progress-step:first-child:before {
    left: 50%;
  }
  
  .progress-step:last-child:before {
    width: 50%;
  }
  
  .progress-step.progress-active {
    color: #FB7A53;
    background-color: #FB7A53;
  }
  
  .progress-step.progress-active:before {
    background-color: #FB7A53;
  }

.section {
  margin-bottom: 40px;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-icon {
  margin-left: 10px;
}

.box {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  width: 65%;
  margin: 0 auto;
}

.button, .generate-button {
  color: white;
  text-align: center;
  width: auto;
  min-width: 220px;
  margin: 0 auto;
  background-color: #5654FB;
  border-radius: 10px;
  padding: 10px 50px;
  font-size: 16px;
  text-align: center;
}

.generate-button {
  background-color: #FB7A53;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.progress-step {
  background-color: #eee;
  padding: 10px 15px;
  border-radius: 10px;
}

.progress-step.active {
  background-color: #FB7A53;
}

.custom-tooltip .tooltip-inner {
  background-color: white;
  color: black;
}

.react-stepper-horizontal .react-stepper-horizontal-dot span {
  display: flex;
  line-height: 30px !important; /* This should match the size prop in the Stepper component */
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}